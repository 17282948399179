body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.pagefade{
  -webkit-animation:fade-in 1.5s ease-in both;
  animation:fade-in 1.5s ease-in both;
  background-color: antiquewhite;
}

@keyframes fade-in {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
   }
}

.header1 {
  font-size: calc(9px + (48 - 14) * ((100vw - 300px) / (1600 - 300)));
  -webkit-font-size: calc(9px + (48 - 14) * ((100vw - 300px) / (1600 - 300)));;
}

