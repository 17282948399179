.home-panel-container {
    /* height: 300px; */
    /* width: 300px; */
   width: 100%;
    height: 60vh;
    position: relative;
    align-items: center;
    background-color: green;

}

.img-slide2{
  height: 95%;
  width: 40vw;
  background-color: aqua;
  position: absolute;
  top: 1vh;
  right: 5%;
  /* transform: translate(-0%, -0%); */
  /* -webkit-animation: slide-container 2s ease-in both;
  animation: slide-container 2s ease-in both; */
}

.text1{
  position: absolute;
  top: 5vw;
  left:2vw;
  font-size: 3rem;
  color: white;
}


.button-link{
  position: absolute;
  /* top: 16vh; */
  bottom: 0;
  margin-bottom: 3vw;
  margin-left: 12vw;


}
 




.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}

.b-example-divider {
  height: 3rem;
  background-color: rgba(0, 0, 0, .1);
  border: solid rgba(0, 0, 0, .15);
  border-width: 1px 0;
  box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
}

.b-example-vr {
  flex-shrink: 0;
  width: 1.5rem;
  height: 100vh;
}

.bi {
  vertical-align: -.125em;
  fill: currentColor;
}

.nav-scroller {
  position: relative;
  z-index: 2;
  height: 2.75rem;
  overflow-y: hidden;
}

.nav-scroller .nav {
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 1rem;
  margin-top: -1px;
  overflow-x: auto;
  text-align: center;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}