$enable-gradients: true;
$enable-shadows : true;
$font-size-base: .8rem ;

// Small devices (landscape phones, 576px and up)
@media (max-width: 576px) { 
    .header{
        font-size: .7rem;
    }
 }



@import "~bootstrap/scss/bootstrap";