.video-container {
    /* height: 300px; */
    /* width: 300px; */
    width:"100%";
    height:"100%";
    position: relative;
}

.video-container video {
  position: relative;
  object-fit: contain;
  z-index: -1;
  opacity: .5;
}

/* Just styling the content of the div, the *magic* in the previous rules */
.video-container .caption {
  /* z-index: 1; */
  position: relative;
  bottom: 0px;
  left: 16px;
  padding: 10px;
/* background-color: aqua; */
}


.bottom-left {
    position: absolute;
    bottom: 8px;
    left: 16px;
  }
  
  /* Top left text */
  .top-left {
    position: absolute;
    top: 8px;
    left: 16px;
  }
  
  /* Top right text */
  .top-right {
    position: absolute;
    top: 8px;
    right: 16px;
  }
  
  /* Bottom right text */
  .bottom-right {
    position: absolute;
    bottom: 0;
    right: 0;
  }
  
  /* Centered text */
  .centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }